import React, { useState, useEffect } from 'react';

import { useAuth } from "../../../hook/AuthContext";
import { useSelector } from "react-redux";

import { cloneDeep, isEqual, differenceWith } from 'lodash';

import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import { NormalTextField } from 'mantisComponents/InputTextField';
import { NormalPickTimeField } from 'mantisComponents/PickTimeField';

import { ReactComponent as CloseDrawerBtn } from 'assets/img/icon/icn_alert_close.svg';
import { ReactComponent as InputEditIcon } from 'assets/img/icon/icn_input_edit_default.svg';
import { ReactComponent as PowerOnBtn } from 'assets/img/icon/icn_power_button_on.svg';
import { ReactComponent as PowerOffBtn } from 'assets/img/icon/icn_power_button_off.svg';
import { ReactComponent as PowerDisabledBtn } from 'assets/img/icon/icn_power_button_disabled.svg';
import { ReactComponent as SwitchOn } from 'assets/img/icon/btn_switch_on.svg';
import { ReactComponent as SwitchOff } from 'assets/img/icon/btn_switch_off.svg';
import { ReactComponent as RemovedBtn } from 'assets/img/icon/icn_table_removed.svg';

import { deviceStatusLabels, productModelDefaultImg, } from '../const';

import { formatToTime } from 'utils/utils';

const plugModalContentRwdStyle = {
  paddingRight: '16px',
  maxHeight: '450px',
  '@media (max-width: 768px)': {
    maxHeight: '200px',
  },
  '@media (min-width: 768px) and (max-width: 1024px)': {
    maxHeight: '250px',
  },
  '@media (min-width: 1024px) and (max-width: 1440px)': {
    maxHeight: '350px',
  },
  '@media (min-width: 1440px) and (max-width: 1920px)': {
    maxHeight: '400px',
  },
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#bad4dd',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'none',
  },
}

const DayCheckbox = ({ checkedDays = [], setCronsData, index }) => {
  const daysOfWeek = [
    { label: '週日', value: 'Sun' },
    { label: '週一', value: 'Mon' },
    { label: '週二', value: 'Tue' },
    { label: '週三', value: 'Wed' },
    { label: '週四', value: 'Thu' },
    { label: '週五', value: 'Fri' },
    { label: '週六', value: 'Sat' },
  ];
  const handleCheckboxChange = (day) => {
    setCronsData((prevCronsData) => {
      const updatedCronsData = [...prevCronsData];
      const targetDays = updatedCronsData[index].days;

      if (targetDays.includes(day)) {
        updatedCronsData[index].days = targetDays.filter((d) => d !== day);
      } else {
        updatedCronsData[index].days = [...targetDays, day];
      }

      return updatedCronsData;
    });
  };

  const getDayIcon = (isChecked, dayLabel) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '52px',
        height: '52px',
        fontWeight: 600,
        fontSize: '14px',
        color: isChecked ? '#ffffff' : '#737791',
        backgroundColor: isChecked ? '#737791' : '#eef0f2',
        borderRadius: '50%',
      }}
    >
      {dayLabel}
    </Box>
  );

  return (
    <Box display="flex" margin='24px'>
      {daysOfWeek.map((day) => (
        <Checkbox
          key={day.value}
          checked={checkedDays.includes(day.value)}
          onChange={() => handleCheckboxChange(day.value)}
          icon={getDayIcon(false, day.label)}
          checkedIcon={getDayIcon(true, day.label)}
        />
      ))}
    </Box>
  );
};

const PlugModalContent = ({
  setIsModalOpen,
  data,
  roomFieldName,
  isDeviceActive,
  handlePowerClick,
  cronsData,
  setCronsData,
  wsData,
  ws,
  setOpenSpinner
}) => {
  const { token } = useAuth();
  const homeGroupId = useSelector((state) => state.user.userInfo).lastUsedHomegroupId;

  const [originalCronsData, setOriginalCronsData] = useState(cloneDeep(cronsData));
  const [removedCronsId, setRemovedCronsId] = useState([]);

  // 檢查 Cron 項目的所有欄位值是否有效
  function isValidCron(cron) {
    return cron.cronName && cron.days.length > 0 && cron.startTime && cron.endTime;
  }

  function handleCloseClick(currentDeviceId) {
    // 檢查 cronsData 和 originalCronsData 是否有變化
    if (!isEqual(cronsData, originalCronsData)) {
      const updateCrons = differenceWith(cronsData, originalCronsData, isEqual);

      const filteredUpdateCrons = updateCrons.filter(isValidCron);

      if (filteredUpdateCrons.length === 0) {
        setIsModalOpen({ modalId: '', state: false });
        return;
      }

      const updateCronsData = [];
      const createCronsData = [];

      filteredUpdateCrons.forEach(item => {
        const formattedItem = {
          ...item,
          startTime: formatToTime(item.startTime),
          endTime: formatToTime(item.endTime)
        };

        if (item.startTimeCronId && item.endTimeCronId) {
          updateCronsData.push(formattedItem);
        } else {
          createCronsData.push(formattedItem);
        }
      });

      if (updateCronsData.length > 0) {
        const requestUpdateCrons = {
          requestId: "TBD",
          resource: `/rooms/devices/${currentDeviceId}/crons`,
          verb: "update",
          accessToken: token,
          data: {
            weeklyJobAtTimes: updateCronsData,
          },
        };
        ws.send(JSON.stringify(requestUpdateCrons));
      }

      if (createCronsData.length > 0) {
        const requestCreateCrons = {
          requestId: "TBD",
          resource: `/rooms/devices/${currentDeviceId}/crons`,
          verb: "create",
          accessToken: token,
          data: {
            homeGroupId,
            weeklyJobAtTimes: createCronsData,
          },
        };
        ws.send(JSON.stringify(requestCreateCrons));
      }

      setOriginalCronsData([]);
      setIsModalOpen({ modalId: '', state: false });
      setOpenSpinner(true);
    } else {
      setIsModalOpen({ modalId: '', state: false });
    }
  }

  function handleTextFieldChange(event, index) {
    const newCronName = event.target.value;
    setCronsData((prevCronsData) => {
      const newCronsData = [...prevCronsData];
      newCronsData[index].cronName = newCronName;
      return newCronsData;
    });
  }

  function handleSwitchClick(state, index) {
    const newCronState = !state;
    setCronsData((prevCronsData) => {
      const newCronsData = [...prevCronsData];
      newCronsData[index].enabled = newCronState;
      return newCronsData;
    });
  }

  function handleTimeChange(date, field, index) {
    const newTime = date;

    setCronsData((prevCronsData) => {
      const newCronsData = [...prevCronsData];
      newCronsData[index][field] = newTime;
      return newCronsData;
    });
  }

  function handleRemoveCronClick(currentDeviceId, targetIndex) {
    const findRemovedCron = cronsData.find((_, i) => i === targetIndex);
    const { startTimeCronId, endTimeCronId } = findRemovedCron

    if (startTimeCronId && endTimeCronId) {
      setRemovedCronsId([{ startTimeCronId, endTimeCronId }])

      const requestDeleteCrons = {
        requestId: "TBD",
        resource: `/rooms/devices/${currentDeviceId}/crons`,
        verb: "delete",
        data: {
          cronIds: [startTimeCronId, endTimeCronId]
        },
        accessToken: token,
      }

      ws.send(JSON.stringify(requestDeleteCrons));
      setOpenSpinner(true);
    } else {
      setCronsData((prevCronsData) => prevCronsData.filter((_, i) => i !== targetIndex));
    }
  }

  function handleAddCronClick() {
    const newCron = {
      enabled: false,
      cronName: "",
      days: [],
      startTime: new Date(),
      endTime: new Date()
    }

    setCronsData((prevCronsData) => {
      const newCronsData = [...prevCronsData];
      newCronsData.push(newCron);
      return newCronsData;
    });
  }

  useEffect(() => {
    if (wsData.updateTag === 'delete_one_devices_crons' && wsData.updateData.errorCode === 0) {
      const updatedCronsData = cronsData.filter((item) =>
        !removedCronsId.some(removeId =>
          item.startTimeCronId === removeId.startTimeCronId && item.endTimeCronId === removeId.endTimeCronId
        ));

      setCronsData(updatedCronsData);
      setOriginalCronsData(updatedCronsData);

      setOpenSpinner(false);
      setRemovedCronsId([]);
    }
  }, [wsData])


  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' margin='0 18px 15px'>
        <Typography sx={{ fontSize: '18px', fontWeight: '600', lineHeight: '1.11', color: '#424962' }}>
          設備狀態
        </Typography>
        <IconButton onClick={() => handleCloseClick(data.id)}>
          <CloseDrawerBtn width="24" height="24" />
        </IconButton>
      </Box>
      <Divider />
      <Box margin='25px 0'>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 100px'>
            <img style={{ width: "120px", height: "92px", marginRight: '8px' }} src={productModelDefaultImg[data.productModel]} alt="productModel" />
            <Box width="400px">
              <Typography sx={{ fontSize: '18px', fontWeight: '600', lineHeight: '1.11', color: '#424962' }}>
                {roomFieldName} - {data.name}
              </Typography>
              <Typography sx={{ fontSize: '18px', fontWeight: '600', lineHeight: '1.11', color: '#424962' }}>
                {deviceStatusLabels[data.status]}
              </Typography>
            </Box>
            <IconButton onClick={() => handlePowerClick(data.id, data.productModel, data.name, data.status)}>
              {(isDeviceActive && <PowerOnBtn width="48" height="48" />)
                || (data.status === 'off' && <PowerOffBtn width="48" height="48" />)
                || (data.status === 'offline' && <PowerDisabledBtn width="48" height="48" />)}
            </IconButton>
          </Box>
        </Grid>
        <Box overflow='auto' sx={{...plugModalContentRwdStyle}}>
        {
          cronsData.length > 0 && cronsData.map((cron, index) => <Grid key={`crons-${data.id}-${index}`} item xs={12}>
            <Box sx={{ margin: '24px 100px', border: '1px solid #eef0f2', borderRadius: '10px' }}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-evenly' alignItems='center' margin='24px'>
                  <NormalTextField
                    id={`plug-modal-${data.id}`}
                    placeholder="排程名稱"
                    value={cron.cronName}
                    onChange={(event) => handleTextFieldChange(event, index)}
                    icon={<InputEditIcon />}
                  />
                  <IconButton
                    sx={{ marginLeft: '20px' }}
                    onClick={() => handleSwitchClick(cron.enabled, index)}
                  >
                    {
                      cron.enabled ? <SwitchOn width="28" height="16" /> : <SwitchOff width="28" height="16" />
                    }
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <DayCheckbox
                  checkedDays={cron.days}
                  setCronsData={setCronsData}
                  index={index}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-evenly' alignItems='center' margin='24px'>
                  <Box width="200px">
                    <NormalPickTimeField
                      initView="hours"
                      showViews={['hours', 'minutes']}
                      timePeriod
                      format="hh:mm"
                      value={new Date(cron.startTime)}
                      onChange={(date) => handleTimeChange(date, 'startTime', index)}
                    />
                  </Box>
                  <Box width="200px">
                    <NormalPickTimeField
                      initView="hours"
                      showViews={['hours', 'minutes']}
                      timePeriod
                      format="hh:mm"
                      value={new Date(cron.endTime)}
                      onChange={(date) => handleTimeChange(date, 'endTime', index)}
                    />
                  </Box>
                  <Box width="24px">
                    <IconButton onClick={() => handleRemoveCronClick(data.id, index)}>
                      <RemovedBtn width="24" height="24" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>)
        }
         </Box>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" margin="24px 157px 0">
            <Box display="flex">
              <Button
                variant="outlined"
                sx={{
                  width: '420px',
                  height: '40px',
                  borderRadius: '10px',
                  borderColor: '#0087a9',
                  padding: '10px 16px',
                  '&:hover': {
                    borderColor: '#0087a9',
                  }
                }}
                onClick={handleAddCronClick}
              >
                <Typography width="100%" color="#0087a9" fontSize="14px" fontWeight="600" lineHeight="1.25">
                  新增時程
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default PlugModalContent