import React, { useState, useEffect, useMemo } from 'react';
import mainStyle from '../device.module.scss';

import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "hook/AuthContext";

import { cloneDeep } from 'lodash';

import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from "@mui/material/IconButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ModalContainer from 'components/ModalContainer';

import { NormalTextField } from 'mantisComponents/InputTextField';

import { ReactComponent as WarningIcon } from 'assets/img/icon/icn_warning.svg';
import { ReactComponent as MoreBtn } from 'assets/img/icon/icn_more_default.svg';
import { ReactComponent as MoreDisabledBtn } from 'assets/img/icon/icn_more_disabled.svg';
import { ReactComponent as CloseDrawerBtn } from 'assets/img/icon/icn_alert_close.svg';
import { ReactComponent as InputEditIcon } from 'assets/img/icon/icn_input_edit_default.svg';
import { ReactComponent as PowerOnBtn } from 'assets/img/icon/icn_power_button_on.svg';
import { ReactComponent as PowerOffBtn } from 'assets/img/icon/icn_power_button_off.svg';
import { ReactComponent as PowerDisabledBtn } from 'assets/img/icon/icn_power_button_disabled.svg';

import videoPlayBtn from 'assets/img/icon/icn_button_play.svg';

import PlugModalContent from './PlugModalContent'

import { deviceStatusLabels, isPoweringState, productModelDefaultImg, productModelDisabledImg } from '../const';

import { convertToDateString } from 'utils/utils';

// 2024Q4 plug 跟 light 是一樣的東西
// 未來會合併
// 合併之後可以將 light 相關判斷移除

const deviceDetailsTextStyle = {
  color: '#737791',
  fontSize: '14px',
  lineHeight: 1.43,
}

const MenuList = ({
  anchorEl,
  setAnchorEl,
  devicesData,
  setDevicesData,
  handleMenuListSettingClick,
  handleMenuListDeleteClick,
  openSettingDrawer,
  setOpenSettingDrawer,
  drawerData,
  roomFieldName,
  openSpinner,
  setOpenSpinner,
  wsData,
  ws,
  token,
}) => {
  // 2024Q4 只先做 plug 的側欄 
  const isOpenDrawerState = drawerData.productModel === 'plug' || drawerData.productModel === 'light' ? true : false

  const [updatedDrawerData, setUpdatedDrawerData] = useState(drawerData);
  // 暫存資料 等到 response 回來才用這個暫存的資料去更新畫面上的資料
  const [temporarilyupdatedDevicesData, setTemporarilyupdatedDevicesData] = useState(null);
  // 使用 useMemo 監聽 避免不必要的渲染跟變化
  const memoizedDevicesData = useMemo(() => devicesData, [devicesData]);
  const memoizedOriginalDevicesData = useMemo(() => drawerData, [drawerData]);

  function handleTextFieldChange(event) {
    const newName = event.target.value;
    setUpdatedDrawerData((prev) => ({ ...prev, name: newName }));
  }

  useEffect(() => {
    if (openSettingDrawer === false && openSpinner === false && updatedDrawerData.name !== memoizedOriginalDevicesData.name) {
      setOpenSpinner(true)

      if (updatedDrawerData.name) {
        let currentDeviceIndex
        const updatedDevicesData = {
          ...memoizedDevicesData,
          devicesInfo: memoizedDevicesData.devicesInfo.map((item, i) => {
            if (item.id === memoizedOriginalDevicesData.id) {
              currentDeviceIndex = i
              return {
                ...item,
                name: updatedDrawerData.name,
              };
            }
            return item;
          }),
        };

        setTemporarilyupdatedDevicesData(updatedDevicesData);

        const requestUpdateOneDeviceName = {
          requestId: "TBD",
          resource: `/rooms/devices/${updatedDevicesData.devicesInfo[currentDeviceIndex].id}`,
          verb: "update",
          data: {
            name: updatedDevicesData.devicesInfo[currentDeviceIndex].name,
          },
          accessToken: token,
        }

        ws.send(JSON.stringify(requestUpdateOneDeviceName));
      } else {
        setUpdatedDrawerData((prev) => ({ ...prev, name: memoizedOriginalDevicesData.name }));
        setOpenSpinner(false)
      }
    }
  }, [openSettingDrawer, updatedDrawerData, memoizedDevicesData, memoizedOriginalDevicesData, setDevicesData, openSpinner])

  useEffect(() => {
    if (wsData.updateTag === "update_one_devices_name" && wsData.updateData.errorCode === 0 && temporarilyupdatedDevicesData) {
      setDevicesData([temporarilyupdatedDevicesData]);
      setTemporarilyupdatedDevicesData(null);
      setOpenSpinner(false)
    }

  }, [wsData, temporarilyupdatedDevicesData])

  const DrawerList = (
    <Box sx={{ width: 430 }} role="presentation">
      <Box display='flex' justifyContent='space-between' alignItems='center' margin='18px 24px'>
        <Typography color="#424962" fontWeight="600" fontSize="18px" lineHeight="1.11">
          裝置設定
        </Typography>
        <IconButton sx={{ width: '20px', height: '20px', padding: 0, }} onClick={() => setOpenSettingDrawer(false)}>
          <CloseDrawerBtn />
        </IconButton>
      </Box>
      <Divider />
      <Grid container spacing={3.5}>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='34px 44px 0'>
            <Typography width="118px" color="#424962" fontWeight="600" fontSize="18px" lineHeight="1.11">
              PM-01M
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <img style={{ width: "120px", height: "92px", marginRight: '8px' }} src={productModelDefaultImg[drawerData.productModel]} alt="productModel" />
            <NormalTextField
              id={`drawer-deviceName-${drawerData.id}`}
              placeholder="裝置名稱"
              value={updatedDrawerData.name}
              onChange={handleTextFieldChange}
              icon={<InputEditIcon />}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <Typography width="118px" color="#424962" fontWeight="600" fontSize="16px" lineHeight="1.25">
              所在場域
            </Typography>
            <Typography color="#737791" fontSize="16px" lineHeight="1.11">
              {roomFieldName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <Typography width="118px" color="#424962" fontWeight="600" fontSize="16px" lineHeight="1.25">
              功能設定
            </Typography>
            <Typography color="#737791" fontSize="16px" lineHeight="1.11">
              電力過載
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <Typography width="118px" color="#424962" fontSize="14px" lineHeight="1.43">
              其他
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <Typography width="118px" color="#424962" fontWeight="600" fontSize="16px" lineHeight="1.25">
              WiFi
            </Typography>
            <Typography color="#737791" fontSize="16px" lineHeight="1.11">
              {drawerData.wifiSSID}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <Typography width="118px" color="#424962" fontWeight="600" fontSize="16px" lineHeight="1.25">
              時區
            </Typography>
            <Typography color="#737791" fontSize="16px" lineHeight="1.11">
              {drawerData.timeZone}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <Typography width="118px" color="#424962" fontWeight="600" fontSize="16px" lineHeight="1.25">
              韌體
            </Typography>
            <Typography color="#737791" fontSize="16px" lineHeight="1.11">
              {drawerData.firmware}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center' margin='0 44px'>
            <Typography width="118px" color="#424962" fontWeight="600" fontSize="16px" lineHeight="1.25">
              MAC ID
            </Typography>
            <Typography color="#737791" fontSize="16px" lineHeight="1.11">
              {drawerData.macAddress}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' alignItems='center' margin='0 44px'>
            <Button
              sx={{
                width: '342px',
                height: '40px',
                borderColor: '#e80000',
                borderRadius: '10px',
                '&:hover': {
                  borderColor: '#ff4d4d',
                },
              }}
              variant="outlined"
              onClick={handleMenuListDeleteClick}
            >
              <Typography width="100%" color="#e80000" fontSize="14px" fontWeight="600" lineHeight="1.25">
                刪除裝置
              </Typography>
            </Button>
          </Box>
        </Grid >
      </Grid >
    </Box >
  );

  return (
    <>
      <Menu
        sx={{ '& .MuiList-root': { padding: 0 } }}
        id="menu-comment"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => handleMenuListSettingClick(isOpenDrawerState)}>
          <Typography color="#737791" fontSize="16px" lineHeight="1.25">設定</Typography>
        </MenuItem>
        <MenuItem onClick={handleMenuListDeleteClick}>
          <Typography color="#e80000" fontSize="16px" lineHeight="1.25">刪除</Typography>
        </MenuItem>
      </Menu>
      <Drawer anchor="right" open={openSettingDrawer} onClose={() => setOpenSettingDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  )
}

const DevicesCard = ({
  currentProsessState,
  devicesData,
  setDevicesData,
  roomFieldName,
  devicesCardContent,
  provided = {},
  openSpinner,
  setOpenSpinner
}) => {
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();
  // 2024Q4 只開放 plug / light 有點擊事件出現 Modal 所以只設定 plug / light 有 pointer 效果
  // 如果是離線卡片也不會有點擊事件
  const cursorStyle = (currentProsessState === 'View'
    && (devicesCardContent.productModel === 'plug' || devicesCardContent.productModel === 'light')
    && devicesCardContent.status !== 'offline' && 'pointer')
    || (currentProsessState === 'Edit' && 'grab') || 'default';
  const isDeviceActive = devicesCardContent.status === 'online' || devicesCardContent.status === 'on';
  const isDeviceInactive = devicesCardContent.status === 'offline' || devicesCardContent.status === 'off';

  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettingDrawer, setOpenSettingDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({ modalId: '', state: false });
  const [cronsData, setCronsData] = useState([]);

  function handleModal(currentDeviceId) {
    if (currentProsessState !== 'View') return;
    // 2024Q4 只開放 plug / light 有點擊事件出現 Modal
    if (devicesCardContent.productModel !== 'plug'
      && devicesCardContent.productModel !== 'light') return;
    // 如果是離線卡片也不會有點擊事件
    if (devicesCardContent.status === 'offline') return;

    setOpenSpinner(true);

    const requestReadOneDeviceCron = {
      requestId: "TBD",
      resource: `/rooms/devices/${currentDeviceId}/crons`,
      verb: "read",
      data: {},
      accessToken: token,
    }

    ws.send(JSON.stringify(requestReadOneDeviceCron));
  }

  function handlePowerClick(currentDeviceId, currentProductModel, currentDeviceName, currentDevicePowerState) {
    setOpenSpinner(true);

    const deviceConfig = {
      plug: { field: "powering" },
      light: { field: "glowing" },
    };

    const { field } = deviceConfig[currentProductModel];

    const requestUpdateOneDevicePower = {
      resource: `/boxes/0/${currentProductModel}s/${currentDeviceId}`,
      verb: "update",
      accessToken: token,
      data: {
        name: currentDeviceName,
        [field]: !isPoweringState[currentDevicePowerState],
      },
    };

    ws.send(JSON.stringify(requestUpdateOneDevicePower));
  }


  function handleMenuListSettingClick(newOpen) {
    setOpenSettingDrawer(newOpen)
    setAnchorEl(null);
  }

  function handleMenuListDeleteClick() {
    setAnchorEl(null);
    openSettingDrawer && setOpenSettingDrawer(false)
  }

  function handleUpdatePowerDeviceStatus(updateTag, updateData) {
    const { resource, data: { online, powering, glowing } } = updateData;
    const deviceId = resource.split("/").pop();

    let newDeviceState;

    switch (true) {
      case !online:
        newDeviceState = "offline";
        break;
      case updateTag === "light_update" && glowing:
      case updateTag === "plug_update" && powering:
        newDeviceState = "on";
        break;
      default:
        newDeviceState = "off";
    }

    return {
      ...devicesData,
      devicesInfo: devicesData.devicesInfo.map((item) => {
        if (item.id === deviceId) {
          return {
            ...item,
            status: newDeviceState,
          };
        }
        return item;
      }),
    };
  };

  useEffect(() => {
    if ((wsData.updateTag === 'light_update' || wsData.updateTag === 'plug_update') && wsData.updateData) {
      const { updateTag, updateData } = wsData;
      const updatedDevicesData = handleUpdatePowerDeviceStatus(updateTag, updateData);
      setDevicesData([updatedDevicesData]);
      setOpenSpinner(false);
    }

    if (wsData.updateTag === 'read_one_devices_crons' && wsData.updateData.errorCode === 0) {
      const { updateData: { resource, data } } = wsData;
      const deviceId = resource.match(/\/rooms\/devices\/(\d+)\/crons/)[1];

      if (data) {
        const readCronsData = cloneDeep(data);
        const formatReadCronsData = readCronsData.map(item => {
          return {
            ...item,
            startTime: convertToDateString(item.startTime),
            endTime: convertToDateString(item.endTime),
          };
        })

        setCronsData(formatReadCronsData);
      } else {
        setCronsData([])
      }

      setOpenSpinner(false);
      setIsModalOpen({
        modalId: deviceId,
        state: true
      });
    }

    if (wsData.updateTag === 'create_one_devices_crons' && wsData.updateData.errorCode === 0) {
      setOpenSpinner(false);
    }

    if (wsData.updateTag === 'update_one_devices_crons' && wsData.updateData.errorCode === 0) {
      setOpenSpinner(false);
    }
  }, [wsData])

  return (
    <>
      <Box
        ref={provided.innerRef || null}
        {...(provided.draggableProps || {})}
        {...(provided.dragHandleProps || {})}
        className={`${mainStyle.draggableItem} ${currentProsessState === 'Edit' ? mainStyle.Edit : ''}`}
        style={{ ...(provided.draggableProps?.style || {}) }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: -0.75 }}>
          <Box display="flex" width="100%" sx={{ cursor: cursorStyle }} onClick={() => handleModal(devicesCardContent.id)}>
            {
              devicesCardContent.productModel !== 'IPCam' &&
              <Box sx={{ marginRight: '10px' }}>
                {
                  (isDeviceActive && <img src={productModelDefaultImg[devicesCardContent.productModel]} alt="productModel" />) ||
                  (isDeviceInactive && <img src={productModelDisabledImg[devicesCardContent.productModel]} alt="productModel" />)
                }
              </Box>
            }
            <Box width="120px" display="flex" flexDirection="column">
              <Typography
                sx={{
                  display: 'inline-block',
                  width: 'calc(100% - 25px)',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  verticalAlign: 'middle',
                  color: devicesCardContent.status === 'offline' ? '#a0a4b0' : '#424962',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: 1.25,
                }}
              >
                {devicesCardContent.name}
              </Typography>

              {
                devicesCardContent.productModel !== 'IPCam' &&
                <Box display="flex" alignItems="center">
                  <Box sx={{
                    backgroundColor: (isDeviceActive && '#24c76f')
                      || (devicesCardContent.status === 'off' && '#a1a1a1')
                      || (devicesCardContent.status === 'offline' && '#cccccc'),
                    width: '10px', height: '10px', borderRadius: '50%', margin: '0 8px 0 1px'
                  }} />
                  <Typography
                    sx={{
                      color: devicesCardContent.status === 'offline' ? '#b9bbc8' : '#737791',
                      fontSize: '14px',
                      lineHeight: 1.43,
                    }}
                  >
                    {deviceStatusLabels[devicesCardContent.status]}
                  </Typography>
                </Box>
              }
            </Box>
          </Box>
          {devicesCardContent.dangerWarning && <WarningIcon />}

          {
            (devicesCardContent.productModel === 'plug' || devicesCardContent.productModel === 'light') &&
            <IconButton
              disabled={devicesCardContent.status === 'offline'}
              onClick={() => handlePowerClick(devicesCardContent.id, devicesCardContent.productModel, devicesCardContent.name, devicesCardContent.status)}
            >
              {(isDeviceActive && <PowerOnBtn />)
                || (devicesCardContent.status === 'off' && <PowerOffBtn />)
                || (devicesCardContent.status === 'offline' && <PowerDisabledBtn />)}
            </IconButton>
          }

          <IconButton
            size="small"
            aria-controls="menu-comment"
            aria-haspopup="true"
            disabled={devicesCardContent.status === 'offline'}
            onClick={(event) => setAnchorEl(event?.currentTarget)}
          >
            {
              ((isDeviceActive || devicesCardContent.status === 'off') && <MoreBtn />) ||
              (devicesCardContent.status === 'offline' && <MoreDisabledBtn />)
            }
          </IconButton>
          <MenuList
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            devicesData={devicesData}
            setDevicesData={setDevicesData}
            openSettingDrawer={openSettingDrawer}
            setOpenSettingDrawer={setOpenSettingDrawer}
            roomFieldName={roomFieldName}
            drawerData={devicesCardContent}
            handleMenuListSettingClick={handleMenuListSettingClick}
            handleMenuListDeleteClick={handleMenuListDeleteClick}
            openSpinner={openSpinner}
            setOpenSpinner={setOpenSpinner}
            wsData={wsData}
            ws={ws}
            token={token}
          />
        </Stack>

        {
          devicesCardContent.productModel === 'IPCam' &&
          <Box sx={{ position: 'relative', width: '256px', height: 'auto', marginTop: '10px' }}>
            <img className={mainStyle.videoImg} src={devicesCardContent.data.thumbnail} alt="Video" />
            <img className={mainStyle.videoPlayBtn} src={videoPlayBtn} alt="videoPlayBtn" />
          </Box>
        }
        {
          devicesCardContent.productModel === 'box' &&
          <Box marginTop="10px">
            <Typography sx={deviceDetailsTextStyle}>
              {devicesCardContent.data.model}
            </Typography>
          </Box>
        }
        {
          devicesCardContent.productModel === 'mmWave' &&
          <Box marginTop="10px">
            {
              devicesCardContent.data.time.map((timeLine, index) => (
                <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={deviceDetailsTextStyle}>
                    {timeLine}
                  </Typography>
                  <Typography sx={deviceDetailsTextStyle}>
                    觸發
                  </Typography>
                </Box>
              ))
            }
          </Box>
        }
      </Box>
      {
        devicesCardContent.id === isModalOpen.modalId &&
        <ModalContainer
          display={isModalOpen.state}
          modalWidth="750px"
          children={
            (devicesCardContent.productModel === 'plug' || devicesCardContent.productModel === 'light') &&
            <PlugModalContent
              setIsModalOpen={setIsModalOpen}
              data={devicesCardContent}
              roomFieldName={roomFieldName}
              isDeviceActive={isDeviceActive}
              handlePowerClick={handlePowerClick}
              cronsData={cronsData}
              setCronsData={setCronsData}
              wsData={wsData}
              ws={ws}
              setOpenSpinner={setOpenSpinner}
            />
          }
        />
      }
    </>
  )
}

export default DevicesCard;
