import React, { useState, useEffect } from 'react'

import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { useSelector } from "react-redux";

import { PATH } from "router/config/const";

import { cloneDeep } from 'lodash';

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import SpeedDial from '@mui/material/SpeedDial';

import Breadcrumb from 'components/Breadcrumb';
import Spinner from 'components/Spinner';

import ViewPage from './ViewPage';
import EditPage from './EditPage';

import { ReactComponent as EditBtn } from 'assets/img/icon/icn_button_edit.svg'

const btnStyle = {
  width: '92px',
  height: '40px',
  borderRadius: '10px',
  borderColor: '#0087a9',
  margin: '5px',
  padding: '10px 16px',
  '&:hover': {
    borderColor: '#0087a9',
  }
}

const Devices = () => {
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const homeGroupId = useSelector((state) => state.user.userInfo).lastUsedHomegroupId;

  const [openSpinner, setOpenSpinner] = useState(false);
  const [currentProsessState, setCurrentProsessState] = useState('View'); // 判斷目前是 Edit, View 
  const [devicesData, setDevicesData] = useState([]);
  const [originalEditDevicesData, setOriginalEditDevicesData] = useState(null);
  const [hasEditRoomFieldsNameError, setEditRoomFieldsNameError] = useState({});

  function handleCancelClick() {
    setDevicesData(originalEditDevicesData)
    setOriginalEditDevicesData(null)
    setEditRoomFieldsNameError({})
    setCurrentProsessState('View')
  }

  function handleSaveClick() {
    setOpenSpinner(true)
    const requestUpdateAllRooms = {
      requestId: "TBD",
      resource: "/rooms",
      verb: "update",
      data: {
        filter: {
          homeGroupId,
        },
        rooms: devicesData[0].roomFields.map((item) => ({
          roomId: item.id,
          name: item.name,
          deviceIdOrder: item.devicesIds
        }))
      },
      accessToken: token,
    }

    ws.send(JSON.stringify(requestUpdateAllRooms));

    setCurrentProsessState('View')
  }

  useEffect(() => {
    if (homeGroupId) {
      setOpenSpinner(true)
      const requestAllRooms = {
        requestId: "TBD",
        resource: "/rooms",
        verb: "read",
        data: {
          filter: {
            homeGroupId,
          },
        },
        accessToken: token,
      };
      ws.send(JSON.stringify(requestAllRooms));
    }
  }, [homeGroupId])

  useEffect(() => {
    if (currentProsessState === 'Edit') {
      setOriginalEditDevicesData(cloneDeep(devicesData))
    }
  }, [currentProsessState])


  useEffect(() => {
    if (wsData.updateTag === "read_all_rooms" && wsData.updateData) {
      const allRoomData = cloneDeep(wsData);
      const readRoomData = allRoomData.updateData;

      const formatRoomData = {
        roomFields: [],
        roomFieldsOrder: [],
        devicesInfo: [],
      };

      readRoomData.forEach((item) => {
        formatRoomData.roomFields.push({
          id: item.roomId,
          name: item.name,
          devicesIds: item.devices.map((devicesItem) => devicesItem.id),
        });

        formatRoomData.roomFieldsOrder.push(item.roomId);

        formatRoomData.devicesInfo.push(
          ...item.devices.map((devicesItem) => ({
            id: devicesItem.id,
            productModel: devicesItem.type,
            name: devicesItem.name,
            status: devicesItem.status,
            wifiSSID: devicesItem.wifiSSID,
            timeZone: devicesItem.timeZone,
            firmware: devicesItem.firmware,
            macAddress: devicesItem.macAddress,
            // 2024Q4 說有很多變數有些 data 先寫死
            data: (devicesItem.type === 'box' && { "model": "情境模式1已啟用" }) ||
              (devicesItem.type === 'mmWave' && { "time": ['9:00 上午', '12:30 下午'] }) ||
              devicesItem.data
          }))
        );
      });
      setDevicesData([formatRoomData])
      setOpenSpinner(false)
    }
    if (wsData.updateTag === "update_all_rooms" && wsData.updateData.errorCode === 0) {
      setOriginalEditDevicesData(null)
      setOpenSpinner(false)
    }
  }, [wsData])


  return (
    <>
      <Spinner open={openSpinner} />
      <Breadcrumb path={PATH.devices}></Breadcrumb>
      {
        devicesData.length > 0 &&
        <Card sx={{ marginTop: '22px', borderRadius: '20px' }}>
          <CardContent sx={{ padding: 0 }}>
            {
              currentProsessState === 'View' &&
              <ViewPage
                currentProsessState={currentProsessState}
                devicesData={devicesData}
                setDevicesData={setDevicesData}
                openSpinner={openSpinner}
                setOpenSpinner={setOpenSpinner}
              />
            }
            {
              currentProsessState === 'Edit' &&
              <>
                <EditPage
                  currentProsessState={currentProsessState}
                  devicesData={devicesData}
                  setDevicesData={setDevicesData}
                  hasEditRoomFieldsNameError={hasEditRoomFieldsNameError}
                  setEditRoomFieldsNameError={setEditRoomFieldsNameError}
                />
                <Box display="flex" justifyContent="center" marginTop="10px">
                  <Box display="flex">
                    <Button variant="outlined" sx={btnStyle} onClick={handleCancelClick}>
                      <Typography width="100%" color="#0087a9" fontSize="14px" fontWeight="600" lineHeight="1.25">
                        取消
                      </Typography>
                    </Button>
                    <Button variant="contained"
                      sx={{
                        ...btnStyle,
                        backgroundColor: '#0087a9',
                        '&:hover': {
                          backgroundColor: '#0087a9',
                        },
                      }}
                      onClick={handleSaveClick}
                      disabled={Object.keys(hasEditRoomFieldsNameError).length}
                    >
                      <Typography width="100%" color="#ffffff" fontSize="14px" fontWeight="600" lineHeight="1.25">
                        確定
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </>
            }
          </CardContent>
        </Card >
      }
      {
        currentProsessState === 'View' && <SpeedDial
          ariaLabel="edit-stage-fab"
          sx={{
            display: 'inline-flex', position: 'sticky', bottom: 80, left: '100%', transform: 'translate(-50%, -50% )',
            '& .MuiFab-primary': {
              width: '56px',
              height: '56px',
              backgroundColor: '#0087a9',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#006b85',
              },
            },
          }}
          icon={<EditBtn />}
          onClick={() => setCurrentProsessState('Edit')}
        />
      }
    </>
  );
};

export default Devices;