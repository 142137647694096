import mainStyle from './sidebar.module.scss';
import { iconTheme, iconTitleTheme, iconThemeSelected } from './style';

import { useEffect, useState } from "react";
// import { useRoute } from '@react-navigation/native';
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import { PATH } from "router/config/const";
import * as Setting from  "../../pages/Login/Setting"
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
// icon
import logo from "assets/img/icon/logo.svg";
import close from "assets/img/icon/icn_nav_close.svg";
import memu from "assets/img/icon/icn_nav_menu.svg";
import dashboard from "assets/img/icon/icn_menu_dashboard.svg";
import dashboard_Select from "assets/img/icon/icn_menu_dashboard_selected.svg";
import recipient from "assets/img/icon/icn_menu_caregiver.svg";
import recipient_Select from "assets/img/icon/icn_menu_caregiver_selected.svg";
import summary from "assets/img/icon/icn_menu_health_monitor.svg";
import summary_Select from "assets/img/icon/icn_menu_health_monitor_selected.svg";
import device from "assets/img/icon/icn_menu_devices.svg";
import device_Select from "assets/img/icon/icn_menu_devices_selected.svg";
import calendar from "assets/img/icon/icn_menu_schedule.svg";
import calendar_Select from "assets/img/icon/icn_menu_schedule_selected.svg";
import event from "assets/img/icon/icn_menu_event_alert.svg";
import event_Select from "assets/img/icon/icn_menu_event_alert_selected.svg";
import setting from "assets/img/icon/icn_menu_user.svg";
import setting_Select from "assets/img/icon/icn_menu_user_selected.svg";
import question from "assets/img/icon/icn_menu_qa.svg";
import question_Select from "assets/img/icon/icn_menu_qa_selected.svg";
import policy from "assets/img/icon/icn_menu_privacy.svg";
import policy_Select from "assets/img/icon/icn_menu_privacy_selected.svg";
import logout from "assets/img/icon/icn_menu_log_out.svg";
import logout_Select from "assets/img/icon/icn_menu_log_out_selected.svg";


// page utils
const Sidebar = () => {
  const mainBarList = [
    {id: '01', src: dashboard, selectSrc: dashboard_Select, params: "dashboard", description: '首頁',permission: true},
    {id: '02', src: recipient, selectSrc: recipient_Select, params: "recipient_setting", description: '長輩資訊',permission: true},
    {id: '03', src: device, selectSrc: device_Select, params: "devices", description: '智慧家庭管理', permission: true},
    {id: '04', src: summary, selectSrc: summary_Select, params: "summary", description: '健康追蹤',permission: true},
    {id: '05', src: calendar, selectSrc: calendar_Select, params: "calendar", description: '行事曆', permission: true},
    {id: '06', src: event, selectSrc: event_Select, params: "event", description: '通知訊息',permission: true},
    // {id: '07', src: setting, selectSrc: setting_Select, params: "user_setting", description: 'User Setting', permission: true},
    // {id: '08', src: setting, selectSrc: setting_Select, params: "add_recipient", description: 'add Recipient', permission: true},
    // {id: '09', src: setting, selectSrc: setting_Select, params: "add_group", description: 'add HomeGroup', permission: true},
    // {id: '10', src: dashboard, selectSrc: dashboard_Select, params: "IoTDashboard", description: 'old Dashboard', permission: true},
  ]
  const secondBarList = [
    {id: '01', src: question, selectSrc: question_Select, params: "question", description: '支援', permission: false},
    {id: '02', src: policy, selectSrc: policy_Select, params: "policy", description: '隱私權政策', permission: false},
    {id: '03', src: logout, selectSrc: logout_Select, params: "logout", description: '登出', permission: true}
  ]

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const location = useLocation();

  let pathParam = location.pathname.split('/')[1];
  if(pathParam === ''){
    pathParam = 'dashboard';
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation" style={{height: '100%', overflow: 'hidden'}} >
      <List>
        <ListItem style={{margin: '35px 0px 25px 10px'}}>
          <img src={logo}/>
          <img style={{paddingLeft: '88px', cursor: 'pointer'}} src={close} onClick={toggleDrawer(false)}/>
        </ListItem>
        {/* <Button disableRipple onClick={handleClick}
          style={{width: '100%', backgroundColor: '#F5F8FF'}}
          sx={{
            "&:hover": {
              backgroundColor: "#FFF"
          }
          }}>
          <Grid container alignItems="center" justifyContent="start" sx={{p: 1}}>
            <div className={mainStyle['drawerItemTest']} >
              <img style={{marginLeft: '10px'}} src={dashboard}/>
              <span style={{marginLeft: '15px', color: '#737791'}}>test</span>
            </div>
          </Grid>
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openAnchor}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>Account</MenuItem>
            <MenuItem onClick={handleClose}>Setting</MenuItem>
          </Menu>
        <Divider /> */}
        {mainBarList.map((list, index) => (
          <ListItem key={index} disablePadding onClick={() => clickPage(list, list.permission)}>
            <Grid container alignItems="center" justifyContent="start" sx={{p: 1}}>
              <div className={pathParam=== list.params? mainStyle['drawerItemSelected']: mainStyle['drawerItem']}
                style={{cursor: (list.permission? 'pointer': 'not-allowed')}}>
                <img style={{marginLeft: '10px'}} src={pathParam=== list.params? list.selectSrc: list.src}/>
                <span style={{marginLeft: '15px'}}>{list.description}</span>
              </div>
            </Grid>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {secondBarList.map((list, index) => (
          <ListItem key={index} disablePadding onClick={() => clickPage(list, list.permission)}>
            <Grid container alignItems="center" justifyContent="start" sx={{p: 1}}>
              <div className={pathParam=== list.params? mainStyle['drawerItemSelected']: mainStyle['drawerItem']}
                style={{cursor: (list.permission? 'pointer': 'not-allowed')}}>
                <img style={{marginLeft: '10px'}} src={pathParam=== list.params? list.selectSrc: list.src}/>
                <span style={{marginLeft: '15px'}}>{list.description}</span>
              </div>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const BarList = (props) => {
    const barList = props.list;
    const currentPage = props.path;
    return (
      <>
        {barList.map((list, index) => (
          <ListItem key={index} disablePadding className={mainStyle['listBox']}>
            <Grid container alignItems="center" justifyContent="space-evenly" sx={{p: 1}} >
              <div style={currentPage=== list.params? iconThemeSelected: iconTheme}
                  onClick={() => clickPage(list, list.permission)}>
                <img src={currentPage=== list.params? list.selectSrc: list.src}/>
              </div>
            </Grid>
          </ListItem>
        ))}
      </>
    )
  }

  const clickPage = (list, permission) => {
    if(list.params === 'logout'){
      // need to logout & redirect to login page
      Setting.logout();
      Setting.goToLink("/");
     /*  setOpen(false);
      let params = list.params
      const url = '/login';
      navigate(url) */

    }else{
      console.log(list)
      setOpen(false);
      if(!permission){
        return;
      }else{
        let params = list.params
        const url = PATH[params].main
        navigate(url)
      }
    }

    // const url = PATH.configure.sw_device.replace(':id', list.params)
  }

  return (
    <>
      <div className={mainStyle['sideBox']}>
        <Box onMouseOver={toggleDrawer(true)} style={{height: '100%'}}>
          <nav aria-label="main mailbox folders" >
            <List style={{padding: '0px'}} >
              <Grid className={mainStyle['listBox']}  container alignItems="center" justifyContent="space-evenly" sx={{p: 1}} >
                <div style={iconTitleTheme} onClick={toggleDrawer(true)} >
                  <Icon>
                    <img src={memu}/>
                  </Icon>
                </div>
              </Grid>
              {/* <Divider style={{marginTop: '10px', marginBottom: '10px'}}/> */}
              <BarList list={mainBarList} path={pathParam}/>
              <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
              <BarList list={secondBarList} path={pathParam}/>
            </List>
          </nav>
        </Box>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
    </>
  );
};

export default Sidebar;