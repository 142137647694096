import mainStyle from "./event.module.scss";
import { useState, useEffect, React, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { cloneDeep } from "lodash";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";

import { ReactComponent as Upper } from "assets/img/icon/btn_arrow_dropdown_upper.svg";
import { ReactComponent as Down } from "assets/img/icon/btn_arrow_dropdown_down.svg";

import dayjs from "dayjs";

import { PATH } from "router/config/const";
import Breadcrumb from "components/Breadcrumb";
import { useNotification } from "../../hook/NotificationContext";
import { selectDate, categoryMapping } from "./const";
import { printDatesInRange } from "./controller";
import CustomDatePicker from "./components/CustomDatePicker";
import CustomSelect from "./components/CustomSelect";

const Event = () => {
  const [wsData, setWsData] = useOutletContext();
  const { unreadCount, setUnReadCount } = useNotification();

  const [messages, setMessages] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [list, setList] = useState(0);
  const messagesToUpdateRef = useRef([]);
  const userInfo = useSelector((state) => state.user.userInfo);
  const homeGroupId = useSelector(
    (state) => state.user.userInfo
  ).lastUsedHomegroupId;
  const ws = useWebSocket();
  const { token } = useAuth();
  const [startDate, setStartDate] = useState(dayjs().subtract(15, "day"));
  const startOfStartDate = startDate.startOf("day");
  const startDateTimestamp = startOfStartDate.unix();

  const [endDate, setEndDate] = useState(dayjs());
  const endOfEndDate = endDate.endOf("day");
  const endDateTimestamp = endOfEndDate.unix();

  const currentDate = dayjs();
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectOpen, setSelectOpen] = useState(false);
  const [category, setCategory] = useState(
    "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness, takeMedicine, takeBloodPressure, outside, hospital, other, deviceChange"
  );
  const previousYearSameDate = currentDate.subtract(365, "day");

  const dates = printDatesInRange(startDate, endDate);
  dates.sort((a, b) => new Date(b) - new Date(a));

  // message composition
  const processDataWithMessage = (data) => {
    if (!data) {
      return [];
    }
    return data.map((item) => {
      const {
        type,
        data: itemData,
        deviceName,
        relevantMembers,
        activityName,
        deviceState,
      } = item;

      let message = "";
      let tag = "";
      let color = "";
      switch (type) {
        // 血壓、心率異常
        case "reportAbnormal":
          color = "#f89898";
          if (itemData) {
            const {
              memberName,
              systolicStatus,
              diastolicStatus,
              heartRateStatus,
            } = itemData;
            if (
              systolicStatus === "high" &&
              diastolicStatus === "high" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時快，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "high" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時低，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "high" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時高，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "low" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時快，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "low" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時慢，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "low" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時高，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時快，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時低，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時高，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "high" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時快，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "high" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時慢，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "high" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時高，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "low" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時低，心率比平時快，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "low" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時低，心率比平時慢，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "low" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時低，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時低，心率比平時快，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時低，心率比平時低，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "low" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時低，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "high" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時高，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "high" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時高，心率比平時低，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "high" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時高，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "low" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的血壓比平時低，心率比平時高，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "low" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的血壓比平時低，心率比平時低，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "low" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時低，請留意${memberName}的狀況。`;
              tag = "血壓心率異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "high"
            ) {
              message = `${memberName}的心率比平時高，請留意${memberName}的狀況。`;
              tag = "心率異常";
            } else if (
              systolicStatus === "normal" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "low"
            ) {
              message = `${memberName}的心率比平時低，請留意${memberName}的狀況。`;
              tag = "心率異常";
            } else if (
              systolicStatus === "high" &&
              diastolicStatus === "normal" &&
              heartRateStatus === "normal"
            ) {
              message = `${memberName}的血壓比平時高，請留意${memberName}的狀況。`;
              tag = "血壓異常";
            }
          }
          break;
        // 睡眠異常
        case "sleepingSickness":
          tag = "作息異常";
          color = "#f89898";
          if (deviceName) {
            message = `偵測到作息異常，請盡快確認狀況！`;
          }
          break;
        // 暴力偵測
        case "abuse":
          tag = "暴力偵測";
          color = "#f89898";
          if (deviceName) {
            message = `${deviceName}偵測到暴力行為，請盡快確認狀況！`;
          }
          break;
        // 跌倒偵測
        case "fallDown":
          tag = "跌倒偵測";
          color = "#f89898";
          if (deviceName) {
            message = `${deviceName}偵測到有人跌倒，請盡快確認狀況！`;
          }
          break;
        // 吃藥提醒
        case "takeMedicine":
          tag = "吃藥提醒";
          color = "#69c0ff";
          if (relevantMembers && relevantMembers.length > 0) {
            message = `${relevantMembers.join(",")}該吃藥囉！`;
          }
          break;
        // 沒吃藥
        case "notTakeMedicine":
          tag = "未按時服藥";
          color = "#f89898";
          if (relevantMembers && relevantMembers.length > 0) {
            message = `${relevantMembers.join(
              ","
            )}忘了吃藥唷！請注意一下${relevantMembers.join(",")}`;
          }
          break;
        // 量血壓提醒
        case "takeBloodPressure":
          tag = "血壓量測提醒";
          color = "#69c0ff";
          if (relevantMembers && relevantMembers.length > 0) {
            message = `${relevantMembers.join(",")}該量血壓囉！`;
          }
          break;
        // 沒量血壓
        case "notTakeBloodPressure":
          tag = "未按時量血壓";
          color = "#f89898";
          if (relevantMembers && relevantMembers.length > 0) {
            message = `${relevantMembers.join(
              ","
            )}忘了量血壓，請注意一下${relevantMembers.join(",")}`;
          }
          break;
        // 外出提醒
        case "outside":
          tag = "外出活動提醒";
          color = "#69c0ff";
          if (activityName && relevantMembers && relevantMembers.length > 0) {
            message = `${relevantMembers.join(
              ","
            )}，提醒您等一下有個外出活動：${activityName}`;
          }
          break;
        // 醫療提醒
        case "hospital":
          tag = "醫療活動提醒";
          color = "#69c0ff";
          if (activityName && relevantMembers && relevantMembers.length > 0) {
            message = `${relevantMembers.join(
              ","
            )}，提醒您等一下有個醫療活動：${activityName}`;
          }
          break;
        // 其他
        case "other":
          tag = "其他活動提醒";
          color = "#69c0ff";
          if (activityName && relevantMembers && relevantMembers.length > 0) {
            message = `${relevantMembers.join(
              ","
            )}，提醒您等一下有個活動：${activityName}`;
          }
          break;
        // IoT狀態改變
        case "deviceChange":
          tag = "裝置狀態";
          color = "#424962";
          if (deviceName) {
            const state = deviceState ? "開啟" : "關閉";
            message = `${deviceName}狀態變更為${state}`;
          }
          break;

        default:
          message = `未定義的事件類型：${type}。`;
      }

      return { ...item, message, tag, color };
    });
  };

  const handleChange = (event) => {
    const selectedCategory = selectDate.find(
      (item) => item.id === event.target.value
    ).content;

    const categories = categoryMapping[selectedCategory] || "";

    setCategory(categories);
    updateEvent();
    setList(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    const adjustedStartDate = newValue.isBefore(previousYearSameDate)
      ? previousYearSameDate
      : newValue;

    setStartDate(adjustedStartDate);
    const newEndDate = adjustedStartDate.add(15, "day").isAfter(currentDate)
      ? currentDate
      : adjustedStartDate.add(15, "day");
    setEndDate(newEndDate);
  };

  const handleEndDateChange = (newValue) => {
    const adjustedEndDate = newValue.isBefore(previousYearSameDate)
      ? previousYearSameDate
      : newValue;

    setEndDate(adjustedEndDate);
    const newStartDate = adjustedEndDate
      .subtract(15, "day")
      .isBefore(previousYearSameDate)
      ? previousYearSameDate
      : adjustedEndDate.subtract(15, "day");
    setStartDate(newStartDate);
  };

  const toggleCollapse = (index) => {
    if (open === index) {
      setOpen(null);
      updateEvent();
    } else {
      setOpen(index);
      if (open !== null) {
        updateEvent();
      }
    }
  };

  const handleOpen = () => {
    setSelectOpen(true);
  };

  const handleClose = () => {
    setSelectOpen(false);
  };

  useEffect(() => {
    const todayIndex = dates.findIndex(
      (date) => date === currentDate.format("MMMM DD YYYY")
    );
    setOpen(todayIndex);
  }, []);

  const updateEvent = () => {
    const messagesToUpdate = messagesToUpdateRef.current;

    const eventUpdateData = {
      resource: `/boxes/0/events`,
      verb: "update",
      accessToken: token,
      data: messagesToUpdate,
    };

    ws.send(JSON.stringify(eventUpdateData));
    localStorage.setItem("ackCount", 0);
    const localAckCount = parseInt(localStorage.getItem("ackCount"), 10);
    setUnReadCount(localAckCount);
  };

  //exit event page
  useEffect(() => {
    const currentDate = dates[open];
    const newMessagesToUpdate = (eventData.data || [])
      .filter(
        (message) =>
          dayjs.unix(message.timestamp).format("MMMM DD YYYY") ===
            currentDate && message.ack === false
      )
      .map((message) => ({
        id: message.id,
        ack: true,
      }));

    messagesToUpdateRef.current = newMessagesToUpdate;
  }, [dates, eventData, open]);

  useEffect(() => {
    return () => {
      updateEvent();
    };
  }, []);

  //init event data
  const getData = async () => {
    const eventSendData = {
      resource: `/boxes/0/events`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: startDateTimestamp,
          endTime: endDateTimestamp,
          category: category,
          userId: userInfo.id,
          homeGroupId: userInfo.lastUsedHomegroupId,
        },
      },
    };
    ws.send(JSON.stringify(eventSendData));
  };

  useEffect(() => {
    if (homeGroupId) {
      getData();
    }
  }, [category, startDateTimestamp, endDateTimestamp, homeGroupId]);

  useEffect(() => {
    if (wsData.updateTag === "event_read" && wsData.updateData) {
      console.log("event read ws", wsData.updateData);
      const processedData = processDataWithMessage(wsData.updateData.data);

      setMessages(processedData);
      setEventData({ ...wsData.updateData, data: processedData });
      setIsLoading(false);
      const { ackCount } = wsData.updateData;
      localStorage.setItem("ackCount", ackCount);
      const localAckCount = parseInt(localStorage.getItem("ackCount"), 10);
      setUnReadCount(localAckCount);
    } else if (wsData.updateTag === "event_update" && wsData.updateData) {
      const updatedEventMessages = (eventData.data || []).map((event) => {
        const update = wsData.updateData.find(
          (update) => update.id === event.id
        );
        if (update) {
          return {
            ...event,
            ack: update.ack,
          };
        }
        return event;
      });

      setEventData((prev) => {
        const updated = {
          ...prev,
          data: updatedEventMessages,
        };
        setMessages(updatedEventMessages);
        return updated;
      });
    } else if (wsData.updateTag === "abnormalEvent" && wsData.updateData) {
      console.log("abnormalEvent update ws", wsData.updateData);
      addNewData(cloneDeep(wsData.updateData));
    }
  }, [wsData]);

  const addNewData = (newData) => {
    const updatedData = cloneDeep(eventData.data || []);

    const processedNewData = Array.isArray(newData)
      ? newData.map((item) => processDataWithMessage([item])[0])
      : processDataWithMessage([newData])[0];

    const updatedProcessedData = {
      ...processedNewData,
    };

    updatedData.push(updatedProcessedData);
    setEventData({ ...eventData, data: updatedData });
    setMessages(updatedData);
  };

  return (
    <>
      <Breadcrumb path={PATH.event}></Breadcrumb>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Card style={{ borderRadius: "20px", width: "100%", height: "100%" }}>
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "large",
                    color: "#424962",
                    fontWeight: "600",
                  }}
                >
                  通知訊息歷史紀錄
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <CustomDatePicker
                      value={startDate}
                      onChange={handleStartDateChange}
                      maxDate={currentDate}
                      minDate={previousYearSameDate}
                    />
                  </Grid>
                  <Grid item sx={{ mt: 1 }}>
                    ~
                  </Grid>
                  <Grid item>
                    <CustomDatePicker
                      value={endDate}
                      onChange={handleEndDateChange}
                      maxDate={currentDate}
                      minDate={previousYearSameDate}
                    />
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      value={list}
                      onChange={handleChange}
                      onOpen={handleOpen}
                      onClose={handleClose}
                      selectOpen={selectOpen}
                      options={selectDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ paddingTop: "20px" }}>
              {isLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <Grid item xs={12} key={index}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Skeleton
                          variant="rectangular"
                          width={40}
                          height={24}
                        />
                        <Skeleton variant="text" width="100%" />
                      </Stack>
                    </Grid>
                  ))
                : dates.map((date, index) => (
                    <Grid item xs={12} key={index}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton
                          edge="start"
                          aria-label={`toggle-details-${date}`}
                          onClick={() => toggleCollapse(index)}
                          sx={{ width: 40, color: "#424962" }}
                        >
                          {open === index ? <Upper /> : <Down />}
                        </IconButton>
                        <Typography sx={{ color: "#424962", fontWeight: 600 }}>
                          {dayjs(date).format("YYYY M月 D日")}
                        </Typography>
                      </Stack>
                      {index < dates.length - 1 && <Divider sx={{ borderColor: "#eef0f2" }} />}
                      <Collapse in={open === index}>
                        <div style={{ paddingLeft: "55px" }}>
                          <Typography
                            component="div"
                            style={{ width: "100%", lineHeight: 3 }}
                          >
                            {messages
                              .filter(
                                (item) =>
                                  dayjs
                                    .unix(item.timestamp)
                                    .format("MMMM DD YYYY") === date
                              )
                              .sort(
                                (a, b) =>
                                  dayjs.unix(b.timestamp).valueOf() -
                                  dayjs.unix(a.timestamp).valueOf()
                              )
                              .map((item, idx) => {
                                const message = item.message;
                                const isNew = !item.ack;
                                const tag = item.tag;
                                const tagColor = item.color;
                                return (
                                  <div
                                    key={idx}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: tagColor,
                                          width: "120px",
                                          height: "28px",
                                          color: "white",
                                          padding: "2px 12px",
                                          marginRight: "20px",
                                          borderRadius: "999px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {tag}
                                      </span>

                                      <span
                                        style={{
                                          color: "#424962",
                                          marginRight: "24px",
                                          fontWeight: isNew ? "bold" : "normal",
                                          width: "80px",
                                        }}
                                      >
                                        {dayjs
                                          .unix(item.timestamp)
                                          .format("A h:mm")
                                          .replace("AM", "上午")
                                          .replace("PM", "下午")}
                                      </span>
                                      <span
                                        style={{
                                          color: "#424962",
                                          fontWeight: isNew ? "bold" : "normal",
                                        }}
                                      >
                                        {message}
                                      </span>
                                    </div>
                                    <Divider sx={{ borderColor: "#eef0f2" }} />
                                  </div>
                                );
                              })}
                            {messages.filter(
                              (item) =>
                                dayjs
                                  .unix(item.timestamp)
                                  .format("MMMM DD YYYY") === date
                            ).length === 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <div style={{ display: "flex", width: "100%" }}>
                                  <span
                                    style={{
                                      color: "#424962",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    No data.
                                  </span>
                                </div>
                                <Divider sx={{ borderColor: "#eef0f2" }} />
                              </div>
                            )}
                          </Typography>
                        </div>
                      </Collapse>
                    </Grid>
                  ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Event;
