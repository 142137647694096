import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";

import { PATH } from "router/config/const";
import Breadcrumb from "components/Breadcrumb";
import UserCard from "./UserCard";
import { panelTheme } from "./style";
import { Box, Card, Divider, Grid, Tab, Tabs, Skeleton } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";

// assets
import { ReactComponent as ProfileSettingIcon } from "assets/img/icon/icn_profile_setting.svg";
import { ReactComponent as ProfileLiveviewIcon } from "assets/img/icon/icn_profile_liveview.svg";

import ElderInfo from "./ElderInfo";
import CareInfo from "./CareInfo";

const CareInformation = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();
  const homeGroupId = useSelector(
    (state) => state.user.userInfo
  ).lastUsedHomegroupId;

  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState("1");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState();
  const [reports, setReports] = useState([]);
  const [elderData, setElderData] = useState([]);
  const [isEditingElder, setIsEditingElder] = useState(false);
  const [isEditingCare, setIsEditingCare] = useState(false);

  const startTime = dayjs().subtract(24, "hour").unix();
  const endTime = dayjs().unix();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEditClickElder = () => {
    if (!isEditingCare) {
      setIsEditingElder(true);
    }
  };

  const handleEditClickCare = () => {
    if (!isEditingElder) {
      setIsEditingCare(true);
    }
  };

  // call homegroup api
  useEffect(() => {
    if (
      homeGroupId !== null &&
      homeGroupId !== undefined &&
      homeGroupId !== ""
    ) {
      const groupSendData = {
        resource: `/homegroups/${homeGroupId}/members/Elder`,
        verb: "read",
        accessToken: token,
      };
      ws.send(JSON.stringify(groupSendData));
    }
  }, [homeGroupId]);

  // call user report api to show abnormal/normal
  useEffect(() => {
    if (userId !== null && userId !== undefined && userId !== "") {
      const reportsSendData = {
        resource: `/users/${userId}/reports`,
        verb: "read",
        accessToken: token,
        data: {
          filter: {
            homeGroupId: homeGroupId,
            startTime: startTime,
            endTime: endTime,
          },
          projection: ["checkAbnormalities"],
        },
      };
      ws.send(JSON.stringify(reportsSendData));
    }
  }, [userId, homeGroupId]);

  useEffect(() => {
    if (userId !== null && userId !== undefined && userId !== "") {
      const sendElderInfo = {
        resource: `/users/${userId}`,
        verb: "read",
        accessToken: token,
        data: {
          filter: {
            homeGroupId: homeGroupId,
          },
        },
      };
      ws.send(JSON.stringify(sendElderInfo));
    }
  }, [userId, homeGroupId]);

  useEffect(() => {
    if (wsData.updateTag === "homegroups_read" && wsData.updateData) {
      const members = wsData.updateData.members;
      const member = wsData.updateData;
      console.log(member);
      if (members && members.length > 0) {
        members.forEach((member) => {
          const userId = member.userId;
          setUserId(userId);
        });
      }
      setUserData(members);
    } else if (wsData.updateTag === "report_read" && wsData.updateData) {
      console.log("Dashboard Tab get read ws");
      const reports = wsData.updateData.abnormalReports;
      setReports(reports);
      setIsLoading(false);
    } else if (wsData.updateTag === "user_read" && wsData.updateData) {
      console.log("get user read");
      setElderData(cloneDeep(wsData.updateData));
      setIsLoading(false);
    }
  }, [wsData]);

  return (
    <>
      <Breadcrumb
        path={PATH.recipient_setting}
        child="長輩資料與照護資訊"
      ></Breadcrumb>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Grid container style={{ paddingTop: "10px", borderRadius: "10px" }}>
          <Grid item style={{ width: isSmallScreen ? "100%" : "300px" }}>
            <Grid container style={{ paddingRight: "25px" }}>
              {isLoading ? (
                Array.from(new Array(1)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width={300}
                    height={120}
                    style={{
                      borderRadius: "20px",
                      marginBottom: "20px",
                    }}
                  />
                ))
              ) : (
                <UserCard userData={userData} reports={reports} />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            style={{ width: isSmallScreen ? "100%" : "calc(100% - 300px)" }}
          >
            <TabContext value={tabValue}>
              <Box
                sx={{ p: 0 }}
                variant="outlined"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Tabs
                  value={tabValue}
                  style={{ minWidth: "50%" }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    sx: { backgroundColor: "white", height: "1px" },
                  }}
                  sx={{
                    "& button": {
                      fontSize: "16px",
                      backgroundColor: "#EEF0F2",
                      color: "#737791",
                    },
                    "& button: active": {
                      backgroundColor: "#FFFFFF",
                      color: "#0087A8",
                    },
                    "& button: focus": {
                      backgroundColor: "#FFFFFF",
                      color: "#0087A8",
                    },
                    "& button: blur": {
                      backgroundColor: "#FFFFFF",
                      color: "#0087A8",
                    },
                    "& .MuiButtonBase-root": {
                      "&.Mui-selected": {
                        fontSize: "18px",
                        fontWeight: 600,
                        border: "1px solid ",
                        borderColor: "#EBEBEB",
                        borderRadius: 1,
                        backgroundColor: "#FFFFFF",
                        color: "#0087A8",
                      },
                    },
                  }}
                >
                  <Tab
                    label="長輩資料與照護資訊"
                    value="1"
                    style={{
                      textTransform: "none",
                      height: "60px",
                      borderRadius: "20px 0px 0 0",
                      minWidth: "244px",
                      border: "none",
                    }}
                  />
                  <Tab
                    label="用藥與量測提醒"
                    value="2"
                    disabled
                    style={{
                      textTransform: "none",
                      height: "60px",
                      borderRadius: "0 20px 0 0",
                      minWidth: "212px",
                      border: "none",
                    }}
                  />
                </Tabs>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ProfileLiveviewIcon style={{ marginRight: "20px" }} />
                  <ProfileSettingIcon />
                </Box>
              </Box>
              <TabPanel value="1" style={panelTheme}>
                <Box sx={{ p: 1 }} variant="outlined">
                  <ElderInfo
                    elderData={elderData}
                    setElderData={setElderData}
                    isLoading={isLoading}
                    wsData={wsData}
                    setWsData={setWsData}
                    homeGroupId={homeGroupId}
                    userId={userId}
                    isEditingCare={isEditingCare}
                    isEditingElder={isEditingElder}
                    setIsEditingElder={setIsEditingElder}
                    handleEditClickElder={handleEditClickElder}
                  />
                </Box>
                <Divider sx={{ borderColor: "#eef0f2" }} />
                <Box sx={{ p: 1 }} variant="outlined">
                  <CareInfo
                    elderData={elderData}
                    setElderData={setElderData}
                    isLoading={isLoading}
                    wsData={wsData}
                    setWsData={setWsData}
                    homeGroupId={homeGroupId}
                    userId={userId}
                    isEditingCare={isEditingCare}
                    isEditingElder={isEditingElder}
                    setIsEditingCare={setIsEditingCare}
                    handleEditClickCare={handleEditClickCare}
                  />
                </Box>
              </TabPanel>
              <TabPanel value="2" style={panelTheme}>
                <Box sx={{ p: 1 }} variant="outlined">
                  Medication and measurement reminders
                </Box>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CareInformation;
