import mainStyle from './breadcrumb.component.scss';
import { breadRoot, breadTarget, breadLink } from './style';
import { useEffect, useState, memo } from 'react';
import { cloneDeep } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATH } from "router/config/const";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumb = props => {
  const {
    path,
    child
  } = props;

  let pathName = path.name;
  let breadPath = path.name;
  let hasRoot = false;

  // let pathName = path.main.split('/')[1];
  // const breadPath = pathName.replace(/^./, pathName[0].toUpperCase());
  // let hasRoot = true;
  // if( pathName === 'dashboard' || pathName === 'question' || pathName === 'policy' || pathName ==='login'){
  //   hasRoot = false;
  // }
  const dashboardRoot = (hasRoot)? true: false;

  const navigate = useNavigate();
  const clickPage = (params) => {
    const url = PATH[params].main
    navigate(url)
  }

  return (
    <>
      <Box className={mainStyle['breadBox']} sx={{ display: 'flex', width: '100%', margin: '10px 0px'}}>
        <span style={breadRoot}>首頁</span>
        {/* { dashboardRoot &&
          <>
          <span style={breadTheme}>{'>'}</span>
          <span style={breadLinkTheme} onClick={() => clickPage('dashboard')}>Dashboard</span>
          </>
        } */}
        { child ?
          <><span style={breadRoot}>{'>'}</span><span onClick={() => clickPage(pathName)} style={breadLink}>{breadPath}</span></>
          :<><span style={breadRoot}>{'>'}</span><span style={breadTarget}>{breadPath}</span></>

        }
        {child &&
          <>
          <span style={breadRoot}>{'>'}</span>
          <span style={breadTarget}>{child}</span>
          </>
        }
        {/* <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb">
          <Link color="#0A8AAB" underline="none">AI</Link>
          { dashboardRoot &&
            <Link style={{ cursor: "pointer" }} color="#0A8AAB" underline="none" onClick={() => clickPage('dashboard')}>Dashboard</Link>
          }
          { child ?
            <Link style={{ cursor: "pointer" }} color="#0A8AAB" underline="none" onClick={() => clickPage(pathName)}>{pathName}</Link>:<Typography color="#0A8AAB">{pathName}</Typography>
          }
          { child && <Typography color="#0A8AAB">child_ID</Typography>}
        </Breadcrumbs> */}
      </Box>
    </>
  );
};

export default memo(Breadcrumb);
